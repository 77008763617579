import React, { useContext, useEffect, useRef, useState } from "react";
import heic2any from "heic2any";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import MaskedInput from "react-text-mask";
import { ReactComponent as ArrowSvg } from "../../assets/img/Arrow.svg";
import { ReactComponent as InfoSvg } from "../../assets/img/info.svg";
import i18n from "../../i18n";
import { getRetailerList } from "../../service/ProductService";
import { AppContext } from "../../store/AppProvider";
import { SHOW_ERROR_ALERT } from "../../store/reducer/AlertReducer";
import { HIDE_LOADER, SHOW_LOADER } from "../../store/reducer/BLoaderReducer";
import { campaignLinks, currencyMask, languageOptions, privacyLinks, purchaseType } from "../../utils/dataConstants";
import { checkFileType, fileSizeRestriction, isEmptyObj } from "../../utils/utils";
import { ButtonLoader } from "../CustomLoader/BackdropLoader";

export const ProductInformation = ({
  yourProduct = {},
  dynProductFormData: { campaignid, mandate_serialnumber, mandate_pricepaid, mandate_proofofpurchase, mandate_retailername, mandate_retailerassociate, mandate_optinmarketing },
  onBackButtonPress,
  onButtonPress,
  show = true,
  showLoadingButton = false
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    resetField,
    setError,
    formState: { errors },
    control,
  } = useForm({
    purchaseDate: '',
    deliveryDate: ''
  });

  const { t } = useTranslation();
  const popInputRef = useRef(null);
  const { setShowAlert, setShowBLoader } = useContext(AppContext);

  const { product_id, sku, purchase_date, delivery_date } = yourProduct || {}
  const [serialNumberDisabled, setSerialNumberDisabled] = useState(false);
  const [selectedPurchaseType, setSelectedPurchaseType] = useState("");
  const [fileTypeError, setFileTypeError] = useState({});
  const { showFileTypeError, fileErrorMessage } = fileTypeError;
  const [retailerOptions, setRetailerOptions] = useState([]);
  const [uploadProof, setUploadProof] = useState({});

  const locale = i18n?.resolvedLanguage;
  const { country } = languageOptions[locale];

  useEffect(() => {
    const fetchRetailers = async () => {
      try {
        setShowBLoader({ action: SHOW_LOADER });
        const data = await getRetailerList(selectedPurchaseType, country);
        setRetailerOptions([...data]);
      } catch (error) {
        setRetailerOptions([]);
      } finally {
        setShowBLoader({ action: HIDE_LOADER });
      }
    };
    if (Boolean(selectedPurchaseType)) {
      fetchRetailers();
    }
  }, [country, selectedPurchaseType]);

  useEffect(() => {
    const currentParams = new URLSearchParams(window?.location?.search?.toLowerCase());
    const serialParamName = currentParams?.get("serial");
    if (Boolean(serialParamName)) {
      setValue('serialNumber', serialParamName);
      setSerialNumberDisabled(true)
    }
  }, []);

  const handlePurchaseType = (type) => {
    setSelectedPurchaseType(type);
    setValue('retailerName', '');
    resetField('retailAssociate');
    setError("retailerName", {
      type: 'required', message: t("validation.retailerNameReq")
    })
  }

  const onFileChange = async (event) => {
    event?.preventDefault();
    const file = event.target?.files[0];
    if (fileSizeRestriction(file)) {
      popInputRef.current.value = "";
      setFileTypeError({ showFileTypeError: true, fileErrorMessage: t("alertMessages.fileSizeExceeded") });
      return;
    }
    const isHeicFile = file?.name?.split('.')[file?.name?.split('.').length - 1] === 'heic'
    if (isHeicFile) {
      uploadHeicFile(file);
    } else {
      if (!checkFileType(file)) {
        popInputRef.current.value = "";
        setFileTypeError({ showFileTypeError: true, fileErrorMessage: t("alertMessages.fileError") });
        return;
      } else {
        const payload = {
          file_name: file?.name,
          file_type: file?.type,
          file_size: file?.size,
          file: file
        };
        setUploadProof(payload);
        setFileTypeError({})
      }
    }
  };

  const uploadHeicFile = async (heicFileData) => {
    try {
      setShowBLoader({ action: SHOW_LOADER });
      const heicFile = await convertHeicToJpeg(heicFileData)
      const payload = {
        file_name: heicFile?.name,
        file_type: heicFile?.type,
        file_size: heicFile?.size,
        file: heicFile,
      };
      setUploadProof(payload);
    } catch (error) {
      popInputRef.current.value = "";
      setShowAlert({ type: SHOW_ERROR_ALERT, message: error.message });
    } finally {
      setFileTypeError({})
      setShowBLoader({ action: HIDE_LOADER });
    }
  }

  const convertHeicToJpeg = async (file) => {
    const jpegFile = await heic2any({
      blob: file,
      toType: 'image/jpeg',
      quality: 0.94,
    });
    jpegFile.lastModifiedDate = new Date();
    jpegFile.name = `${file?.name?.split('.')?.[0]}.jpg`;
    return jpegFile
  }

  const removeProofRef = () => {
    popInputRef.current.value = "";
    setUploadProof({})
  }

  const onError = (errors, event) => {
    var form = document.getElementById("multiCollapseExample3");
    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
    }
    form.classList.add("was-validated");
  };

  const notValidData = (formData) => {
    const { retailerName } = formData
    if (!Boolean(retailerName) || !Boolean(selectedPurchaseType)) {
      return true
    }
    if (Boolean(mandate_proofofpurchase) && !Boolean(uploadProof?.file_name)) {
      return true
    }
    return false
  }

  const onSubmit = (formData, event) => {
    try {
      event?.preventDefault()
      if (notValidData(formData)) {
        var form = document.getElementById("multiCollapseExample3");
        if (!form.checkValidity()) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add("was-validated");
        return;
      }
      const { file_name, file_type, file_size, file } = uploadProof;
      const product = {
        product_id,
        sku,
        purchase_date,
        delivery_date,
        locale,
        serial_number: formData?.serialNumber?.trim(),
        retailer_id: JSON.parse(formData?.retailerName)?.retailer_id || null,
        retailer_name: JSON.parse(formData?.retailerName)?.retailer_name || null,
        retail_associate: formData?.retailAssociate,
        price_paid: formData?.pricePaid || 0,
        file_name: file_name || "",
        file_type: file_type || "",
        file_size,
        purchaseType: selectedPurchaseType,
        receive_product_communication: formData?.receive_product_communication,
      };
      onButtonPress(product, file);
    } catch (error) {
      setShowAlert({ type: SHOW_ERROR_ALERT, message: t('alertMessages.error') });
    }
  };

  const showRetailAssociate = selectedPurchaseType === purchaseType.inStore && (Boolean(campaignid) && campaignid !== 'DEFAULT')

  return (
    <form
      className={`collapse multi-collapse ${show ? "show" : "d-none"} needs-validation`}
      id="multiCollapseExample3"
      onSubmit={handleSubmit(onSubmit, onError)}
      noValidate
      autoComplete="off"
    >
      {(Boolean(campaignid) && campaignid !== 'DEFAULT') &&
        (<div className='info-notifier flex-wrap form-group-wrapper mt-3 mb-3'>
          {t('productRegistration.campaignAvl')}
          &nbsp;<a href={`//${campaignLinks[locale]}`} target="_blank">{campaignLinks[locale]}</a>&nbsp;
          {t('productRegistration.for details')}
        </div>)}
      <div className="d-grid justify-content-between flex-wrap form-group-wrapper margin-label">
        <div className="form-group px-0 d-flex flex-column-reverse justify-content-end">
          <a
            id="SerialHelp"
            className="form-text order-2"
            href="https://www.sony.com.sg/HP/pages/html/sg/help/Product_Help.html"
            target="_blank"
          >
            <InfoSvg />&nbsp;
            {t(`productRegistration.Where's my serial number?`)}
          </a>
          <input
            type="text"
            className="form-control order-3"
            id="exampleInputNumber"
            required={Boolean(mandate_serialnumber)}
            name="serialNumber"
            disabled={serialNumberDisabled}
            {...register("serialNumber", {
              required: Boolean(mandate_serialnumber) && t("validation.serialReq")
            })}
          />
          <label
            htmlFor="exampleInputNumber"
            className="invalid-feedback form-label order-4"
          >
            {Boolean(mandate_serialnumber) ? t("productRegistration.Serial Number *") : t("productRegistration.Serial Number (optional)")}
          </label>
          {errors?.serialNumber &&
            <div className="invalid-feedback order-1">
              {errors?.serialNumber?.message}
            </div>}
        </div >
        <div className="form-group d-md-flex d-none"></div>
        <div className="form-group px-0 d-flex flex-column-reverse justify-content-end">
          <Controller
            name="pricePaid"
            control={control}
            rules={{
              required: Boolean(mandate_pricepaid) && t("validation.priceReq")
            }}
            render={({ field }) =>
              <MaskedInput
                {...field}
                name="pricePaid"
                id="exampleInputPrice"
                mask={currencyMask}
                className="form-control order-2"
                required={Boolean(mandate_pricepaid)}
              />}
          />
          <label
            htmlFor="exampleInputPrice"
            className="form-label order-2"
          >
            {Boolean(mandate_pricepaid) ? t("productRegistration.Price Paid *") : t("productRegistration.Price Paid (optional)")}
          </label>
          {errors?.pricePaid &&
            <div className="invalid-feedback order-1">
              {errors?.pricePaid?.message}
            </div>}
        </div>
        <div className="form-group px-0 d-flex flex-column-reverse justify-content-end">
          <input
            className="form-control order-2"
            type="file"
            id="formFile"
            required={Boolean(mandate_proofofpurchase)}
            onChange={(e) => onFileChange(e)}
            onClick={() => removeProofRef()}
            accept={"application/pdf,image/*,.heic,.heif"}
            ref={popInputRef}
          />
          <label htmlFor="formFile" className="form-label order-2">
            {Boolean(mandate_proofofpurchase) ? t("productRegistration.Proof of Purchase *") : t("productRegistration.Proof of Purchase (optional)")}
          </label>
          {(showFileTypeError) &&
            <div className="error-field">
              <div className="error-field-text">
                {fileErrorMessage}
              </div>
            </div>}
          {isEmptyObj(uploadProof) &&
            <div className="invalid-feedback order-1">
              {t('validation.popReq')}
            </div>}
        </div >
        <div className="form-group modal-form-group px-0 d-flex flex-column-reverse justify-content-end">
          <div
            className="form-check order-2"
            onChange={() => handlePurchaseType(purchaseType.online)}
          >
            <input
              type="radio"
              className="form-check-input"
              id="purchasedOnlineStepper"
              name="radio-stacked"
              required
            />
            <label
              className="form-check-label"
              htmlFor="purchasedOnlineStepper"
            >
              {t("productRegistration.Purchased Online")}
            </label>
          </div>
          <div
            className="form-check mb-3 order-2"
            onChange={() => handlePurchaseType(purchaseType.inStore)}
          >
            <input
              type="radio"
              className="form-check-input"
              id="purchasedInStoreStepper"
              name="radio-stacked"
              required
            />
            <label
              className="form-check-label"
              htmlFor="purchasedInStoreStepper"
            >
              {t("productRegistration.Purchased In-store")}
            </label>
          </div>
          <input
            type="radio"
            className="form-check-input d-none"
            id="errorCheckMessageTrigger"
            name="radio-stacked"
            required
          />
          <div className="invalid-feedback order-1">
            {!Boolean(selectedPurchaseType) && t("validation.purchaseTypeReq")}
          </div>
          <div htmlFor="errorMessageTrigger"
            className="form-label order-2"
          >
            {t("productRegistration.Did you purchase this online or in-store?")}&nbsp;*
          </div>
        </div>
        <div className="form-group d-md-flex d-none"></div>
        {
          Boolean(selectedPurchaseType) &&
          <div className="form-group px-0 d-flex flex-column-reverse justify-content-end">
            <select
              className="form-select order-2"
              id="exampleInputRetailer"
              name="retailerName"
              defaultValue={""}
              required={Boolean(mandate_retailername)}
              {...register("retailerName", {
                required: Boolean(mandate_retailername) && t("validation.retailerNameReq")
              })}
            >
              <option disabled value=""></option>
              {retailerOptions.map((retailer) => (
                <option
                  value={JSON.stringify(retailer)}
                  key={retailer?.retailer_id}
                >
                  {retailer?.display_text.toUpperCase()}
                </option>
              ))}
            </select>
            <label
              htmlFor="exampleInputRetailer"
              className="select invalid-feedback form-label order-2"
            >
              {Boolean(mandate_retailername) ? t("productRegistration.Retailer Name *") : t("productRegistration.Retailer Name (optional)")}
            </label>
            {errors?.retailerName &&
              <div className="invalid-feedback order-1">
                {errors?.retailerName?.message}
              </div>}
          </div>
        }
        {showRetailAssociate &&
          <div className="form-group px-0 d-flex flex-column-reverse justify-content-end">
            <input
              name="retailAssociate"
              type="text"
              className="form-control order-2"
              id="retailAssociate"
              placeholder={t("productRegistration.Retail Associate Placeholder")}
              required={Boolean(mandate_retailerassociate)}
              style={{ borderColor: errors?.retailAssociate && '#dc3545' }}
              {...register("retailAssociate", {
                required: Boolean(mandate_retailerassociate) && "Retail Associate is required",
                maxLength: {
                  value: 50,
                  message: t("validation.maxEmailLength")
                }
              })}
            />
            <label
              htmlFor="retailAssociate"
              className="invalid-feedback form-label order-2"
              style={{ color: errors?.retailAssociate && '#dc3545' }}
            >
              {Boolean(mandate_retailerassociate) ? t("productRegistration.Retail Associate *") : t("productRegistration.Retail Associate (optional)")}
            </label>
            {errors?.retailAssociate &&
              <div className="error-field">
                <div className="error-field-text">
                  {errors?.retailAssociate?.message}
                </div>
              </div>}
          </div>
        }
        <div className="form-group form-check form-group__2span">
          <input
            className="form-check-input checked"
            type="checkbox"
            required={Boolean(mandate_optinmarketing)}
            id="flexCheckDefault"
            name="receive_product_communication"
            {...register("receive_product_communication", {
              required: Boolean(mandate_optinmarketing) && t("validation.agreement")
            })}
          />
          {country === 'us' &&
            <label htmlFor="flexCheckDefault" className="form-check-label">
              {t("productRegistration.receiveEmailCommunication")}
              <br />
              {t("productRegistration.agreesSonyElectronics")}
              <a
                href={privacyLinks[`${country}PrivacyPolicyLink`]}
                target="_blank"
              >
                &nbsp;{t("productRegistration.Privacy Policy")} &nbsp;
              </a>
              {t("productRegistration.and certify that I am a U.S. resident.")}
              <a
                href={privacyLinks[`${country}PrivacyNoticeLink`]}
                target="_blank"
              >
                &nbsp;{t("productRegistration.CA Privacy Notice")}
              </a>
            </label>
          }
          {country === 'ca' && locale === 'en_CA' &&
            <label htmlFor="agreement" className="form-check-label">
              {t("productRegistration.CA ReceiveEmailCommunication")}&nbsp;
              <a
                href={privacyLinks[`${country}PrivacyNoticeLink`]}
                target="_blank"
              >
                {t("productRegistration.Privacy Policy")}&nbsp;
              </a>
              {t('productRegistration.or')}&nbsp;
              <a
                href="https://corporate.sony.ca/view/contact_info.htm"
                target="_blank"
              >
                {t("productRegistration.Contact Us")}&nbsp;
              </a>
              {t('productRegistration.moreDetails')}
              <br />
              {t("productRegistration.CA AgreesSonyCanada")}
              <a
                href={privacyLinks[`${country}PrivacyNoticeLink`]}
                target="_blank"
              >
                &nbsp;{t("productRegistration.Privacy Policy")}&nbsp;
              </a>
              {t("productRegistration.CA Resident")}
            </label>
          }
          {country === 'ca' && locale === 'fr_CA' &&
            <label htmlFor="agreement" className="form-check-label">
              {t("productRegistration.CA_FR_emailCommunication")}&nbsp;
              {t("productRegistration.CA_FR_refer")}&nbsp;
              <a
                href={privacyLinks[`${country}PrivacyNoticeLink`]}
                target="_blank"
              >
                {t("productRegistration.CA_FR_privacyPolicy")}&nbsp;
              </a>
              {t('productRegistration.CA_FR_sonyCanada')}&nbsp;
              {t('productRegistration.or')}&nbsp;
              <a
                href="https://corporate.sony.ca/view/contact_info.htm"
                target="_blank"
              >
                {t("productRegistration.CA_FR_contactUs")}&nbsp;
              </a>
              {t('productRegistration.CA_FR_moreDetails')}
              <br />
              {t("productRegistration.CA_FR_agree")}&nbsp;
              <a
                href={privacyLinks[`${country}PrivacyNoticeLink`]}
                target="_blank"
              >
                {t("productRegistration.CA_FR_privacyPolicy")}&nbsp;
              </a>
              {t("productRegistration.CA_FR_sonyCanada")}&nbsp;
              {t("productRegistration.CA_FR_andCertify")}
            </label>
          }
          {errors?.receive_product_communication &&
            <div className="invalid-feedback">
              {errors?.receive_product_communication?.message}
            </div>}
        </div>
        <div
          id="return"
          className="row d-flex justify-content-between submit-block form-group__2span"
        >
          <div className="d-flex justify-content-between p-0">
            <ArrowSvg />
            <button
              type="button"
              className="return"
              aria-expanded="false"
              aria-controls="multiCollapseExample1 multiCollapseExample2 multiCollapseExample3 multiCollapse1 multiCollapse2 multiCollapse3"
              onClick={() => onBackButtonPress()}
            >
              {t("productRegistration.Return")}
            </button>
          </div>
          {showLoadingButton
            ? <ButtonLoader />
            : <button id="submit" type="submit" className="login Submit"> {t('productRegistration.Submit Registration')}</button>
          }
        </div>
      </div >
    </form >
  );
};